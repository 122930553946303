/* Load this file after the footer in your website or bundle with your other js */

function observeImages() {
	/* Get all lazy sources */
	let lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
	let lazySources = [].slice.call(document.querySelectorAll("source.lazy"));
	let lazyBackgrounds = [].slice.call(document.querySelectorAll(".bg-lazy"));
	/*Determin offset*/
	let lazyOffset = "200px 0px 200px 0px";

	/* Check if browser is IE*/
	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");
	if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))
	{
		/* If so, load images directly*/
		for (var i = 0; i < lazyImages.length; i++) {
			let getSrc = lazyImages[i].getAttribute('data-src');
			lazyImages[i].setAttribute('src', getSrc);
			lazyImages[i].classList.remove('lazy');
		}
		for (var i = 0; i < lazySources.length; i++) {
			let getSrcSet = lazySources[i].getAttribute('data-srcset');
			lazySources[i].setAttribute('srcset', getSrcSet);
			lazySources[i].classList.remove('lazy');
		}
	} else {
		/* Detect if in viewport and load images */
		if ("IntersectionObserver" in window) {
			/* Else apply lazyloading */
			let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
				entries.forEach(function(entry) {
					if (entry.isIntersecting) {
						let lazyImage = entry.target;
						lazyImage.src = lazyImage.dataset.src;
						lazyImage.classList.remove("lazy");
						lazyImageObserver.unobserve(lazyImage);
					}
				});
			}, {
				rootMargin: lazyOffset
			});

			lazyImages.forEach(function(lazyImage) {
				lazyImageObserver.observe(lazyImage);
			});

			let lazySourceObserver = new IntersectionObserver(function(entries, observer) {
				entries.forEach(function(entry) {
					if (entry.isIntersecting) {
						let lazyImage = entry.target;
						lazyImage.srcset = lazyImage.dataset.srcset;
						lazyImage.classList.remove("lazy");
						lazySourceObserver.unobserve(lazyImage);
					}
				});
			}, {
				rootMargin: lazyOffset
			});

			lazySources.forEach(function(lazyImage) {
				lazySourceObserver.observe(lazyImage);
			});


			let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
				entries.forEach(function(entry) {
					if (entry.isIntersecting) {
						let lazyImage = entry.target;
						lazyImage.classList.remove("bg-lazy");
						lazySourceObserver.unobserve(lazyImage);
					}
				});
			}, {
				rootMargin: lazyOffset
			});

			lazyBackgrounds.forEach(function(lazyImage) {
				lazyBackgroundObserver.observe(lazyImage);
			});
		}
	}

}

/* Execute after DOM loaded */
document.addEventListener("DOMContentLoaded", function() {
	observeImages();
});
