async function playVideo() {
	let videos = document.querySelectorAll('.video-element__play');
	console.log(videos);
    if (videos) {
		for (var i = 0; i < videos.length; i++) {
			let thisVideo = videos[i];
			thisVideo.addEventListener('click', function(e) {
				e.preventDefault();
				let targetId = this.getAttribute('data-id');
				let targetSource = document.getElementById(targetId).getAttribute('video-src');
				
				console.log(targetId, targetSource);
				document.getElementById(targetId).setAttribute('src', targetSource);
				document.getElementById(targetId).parentElement.classList.add('video--loaded');
			})
		}
	}
}
export {
	playVideo
}
