// Import Swiper and modules
var $ = require("../../../config/node_modules/jquery");
import {
	Swiper,
	Mousewheel,
	Navigation,
	Pagination,
	Scrollbar,
	Controller,
	EffectFade
} from '../../../config/node_modules/swiper/swiper.esm';
Swiper.use([Mousewheel, Navigation, Pagination, Scrollbar, Controller, EffectFade]);

var swiper1 = new Swiper('.normal-slider', {
	autoplay: false,
	slidesPerView: 1,
    spaceBetween: 62,
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
	mousewheel: {
		forceToAxis: true, 
	},
	effect: 'fade',
	fadeEffect: {
		crossFade: true
    },

});



var swiper2 = new Swiper('.usp-slider', {
	autoplay: false,
	slidesPerView: 1,
    spaceBetween: 0,
	mousewheel: {
		forceToAxis: true, 
    },
  

    breakpoints: {
        // when window width is >= 320px
        780: {
          slidesPerView: 2,
        },
        // when window width is >= 480px
        1024: {
          slidesPerView: 3,
        },
        // when window width is >= 640px
        1200: {
          slidesPerView: 4
        }
      }
});

// THROTTLE FUNCTION
const throttle = function (func, limit) {
    let lastFunc;
    let lastRan;
    return function () {
        const context = this;
        const args = arguments;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
};

$(window).scroll(function() {
    console.log($(window).scrollTop())
	if ($(window).scrollTop() >= 40) {
		$('.navigation').addClass('fixed-header');
	} else {
		$('.navigation').removeClass('fixed-header');
	}
});

$('.navbar-toggler').click(throttle(function() {
    var nav = $('.navigation');
    $(nav).toggleClass('menu-open');
    $(".main-content-wrapper").toggleClass("pushed");
    if ($('.navbar-collapse').hasClass("pushed")) {
        $(".dropdown-item").toggleClass("wow animated fadeInRight");
    }
    else {
        $(".nav-item").toggleClass("");
    };
}, 1200));